import React from 'react'
import ComingSoonComponent from '../components/ComingSoon'
import {Container} from '../theme/elements'
import ReactPixel from 'react-facebook-pixel'
import {FBPixel} from '../config'
ReactPixel.init(FBPixel);

class ComingSoon extends React.Component<any> {
  componentDidMount(): void {
    ReactPixel.pageView()
  }

  render() {
    return (
      <Container>
        <ComingSoonComponent/>
      </Container>
    )
  }
}


export default ComingSoon
