import {Stores} from '../'
import {observable, action} from 'mobx'

export default class GeneralStore {
  @observable loading:any = {
    general: false
  }
  constructor(public stores: Stores) {
  }
  @action.bound
  setLoading(target:string, newState:boolean) {
    this.loading[target] = newState
  }
  @action.bound
  resetLoading() {
    this.loading.map((item:boolean, key:any) => this.loading[key] = false)
  }
}
