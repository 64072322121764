import {createGlobalStyle} from 'styled-components'
import {breakpoints} from './breakpoints'
import {colors} from './colors'
import reset from 'styled-reset'

export const GlobalStyle = createGlobalStyle`
${reset}

html {
  -webkit-font-smoothing: antialiased
}

body {
  font-family : 'Orbitron', 'Nunito Sans', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition-delay: 99999s
}

a {
  text-decoration: none
  transition: color 0.35s ease-in-out;
  color: ${colors.orange};
  
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
`

export const Theme = {
  breakpoints,
  colors
}
