import GeneralStore from './general'

export class Stores {
  public generalStore: GeneralStore

  constructor() {
    this.generalStore = new GeneralStore(this);
  }
}

export const stores = new Stores();
