import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 15px;
  justify-content: space-between;
  z-index: 2;
  opacity: 1;
  transition: all 0.35s ease-in-out;
  
  &.loading {
    opacity: 0;
  }
  
  ${breakpoint('sm', 'sm')`
    display:none;`}
    
  ${breakpoint('md')`
    width:750px;`}
    
  ${breakpoint('lg')`
    width:975px;`}
    
  ${breakpoint('xl')`
    width:1170px;`}
  `
export const Row = styled.div`
  display:flex;
  flex-direction:row;
  margin: 0;
  padding: 0;
  z-index: 3;
`
