import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'
import {observable} from 'mobx'
import { Link } from 'react-router-dom'
import { FaFacebookSquare, FaTwitter, FaBars, FaInstagram } from 'react-icons/fa'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

@inject('generalStore')
@observer
class Header extends Component<any>{
  @observable mobileMenuShowing:boolean = false;
  showMobileMenu = (e:any) => {
    e.preventDefault()
    this.mobileMenuShowing = true;
  }
  closeMobileMenu = () => {
    const {generalStore:{setLoading}} = this.props
    this.mobileMenuShowing = false;
    setLoading('general', true)
  }
  render() {
    return (
      <HeaderWrapper>
        <NavigationBar>
          <LeftNavBar>
            <MobileMenuTrigger href={`#`} onClick={this.showMobileMenu}><FaBars size={32}/></MobileMenuTrigger>
            <NavLink href={`https://www.instagram.com/RBGamesLive`} target={`_blank`}><FaInstagram size={32}/></NavLink>
            <NavLink href={`https://www.facebook.com/RBGamesLive`} target={`_blank`}><FaFacebookSquare size={32}/></NavLink>
            <NavLink href={`https://www.twitter.com/RBGamesLive`} target={`_blank`}><FaTwitter size={32}/></NavLink>
          </LeftNavBar>
          <RightNavBar>
            <AppLink to={`/`}>Home</AppLink>
            <AppLink to={`/about`}>About</AppLink>
            <AppLink to={`/games`}>Games</AppLink>
            <AppLink to={`/stream`}>LIVE Stream</AppLink>
            <AppLink to={`/blog`}>Blog</AppLink>
            <AppLink to={`/contact`}>Contact</AppLink>
          </RightNavBar>
          <MobileMenu className={this.mobileMenuShowing ? 'shown' : ''} onClick={this.closeMobileMenu}>

          </MobileMenu>
          <MobileUnderlay className={this.mobileMenuShowing ? 'shown' : ''} onClick={this.closeMobileMenu} />
        </NavigationBar>
      </HeaderWrapper>
    )
  }
}
export default Header
/* Styles */
const HeaderWrapper = styled.header`
  margin: 20px 0;`
const NavigationBar = styled.div`
  height: 60px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;`
const LeftNavBar = styled.div`
  display:flex;
  flex-direction:row;`
const RightNavBar = styled.div`
  display:flex;
  flex-direction:row;`
const StyledLink = styled.a`
  display:flex;
  height: 60px;
  min-width: 60px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  background-color: rgba(255, 127, 0, 0);
  &:hover {
    background-color: rgba(255, 127, 0, 0.3);
    border-radius: 0;
  }
  ${breakpoint('xs', 'md')`
    display:none;
  `}`
const MobileMenuTrigger = styled(StyledLink)`
  display:none;
  ${breakpoint('xs', 'md')`
    display:flex;
  `}`
const NavLink = styled(StyledLink)`
  font-family: "Orbitron", "Nonito Sans", Helvetica, Arial, sans-serif;`
const AppLink = styled(Link)`
  display:flex;
  height: 60px;
  min-width: 60px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  background-color: rgba(255, 127, 0, 0);
  &:hover {
    background-color: rgba(255, 127, 0, 0.3);
    border-radius: 0;
  }
  padding: 0 10px;
  ${breakpoint('xs', 'md')`
    display:none;
  `}`
const MobileUnderlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;
  display:none;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  &.shown {
    display: block;
    opacity: 1;
  }`
const MobileMenu = styled.div`
  position:fixed;
  width: 70vw;
  height: 100vh;
  max-width: 300px;
  top: 0;
  left: -300px;
  z-index: 100;
  transition: left 0.35s ease-in-out;
  &.shown {
    left: 0;
  }
  `
