import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'
import {BrowserRouter, Route} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import ComingSoon from '../views/ComingSoon'
import Home from '../views/Home'
import {CDN} from '../config'
import styled from 'styled-components'
import {GlobalStyle} from '../theme'

@inject('generalStore')
@observer
class App extends Component<any> {
  render() {
    const {generalStore:{loading}} = this.props
    return (
      <BrowserRouter>
        <RouterWrapper>
          <Route exact path='/' component={ComingSoon}/>
          <Route exact path='/home' component={Home}/>
          <ToastContainer autoClose={3000} closeButton={false} hideProgressBar={true}/>
          <VideoBackground autoPlay muted loop poster={`${CDN}/img/bg-static-circle.jpg`}>
            <source src={`${CDN}/video/bg.mp4`} type="video/mp4"/>
          </VideoBackground>
          <VideoLoader autoPlay muted loop poster={`${CDN}/img/bg-static-circle.jpg`} className={loading.general ? `shown` : ''}>
            <source src={`${CDN}/video/bg-circle.mp4`} type="video/mp4"/>
          </VideoLoader>
          <DarkOverlay className={loading.general ? 'loading' : ''} />
        </RouterWrapper>
        <GlobalStyle/>
      </BrowserRouter>
    )
  }
}
/*
<span><div class="_2l6d _4-u2 _4-u8"><div class="_2l6e _8cjq"><div class="_3qn7 _61-3 _2fyi _3qnf _4tau"><div class="_6g3g" style="flex: 1 1 50%; order: 0;"><div><div class="_3qn7 _61-0 _2fyi _3qnf"><div class="_2l6g"><img class="_2l6h img" src="https://scontent.xx.fbcdn.net/v/t1.0-1/p50x50/53013932_10213056605158788_5125115265213792256_n.jpg?_nc_cat=107&amp;_nc_oc=AQn_YN3rPe1NYgR8YzfZzLTTq6vF6H6G841btZ_hhP8dUrifofjznkSL3GHYPRVr2vRPQYLANAytqUyJALGEg03A&amp;_nc_ad=z-m&amp;_nc_cid=0&amp;_nc_zor=9&amp;_nc_ht=scontent.xx&amp;oh=a8857018964cd1cc7132e781a3f37f94&amp;oe=5E610088" alt=""></div><div class="_2l6i"><div class="_6qad"><a class="_2l6j" uid="1502794359" data-hovercard="/ajax/hovercard/user.php?id=1502794359" href="/1502794359">Robert Bojor</a></div><div class="_2l6k"><span>just now</span></div></div></div><div class="_2l6n"><span><span>Lorem ipsum id class velit name phasellus cursus, vulputate augue ornare lobortis vitae lacus.</span></span></div><div class="_2l6l _3qn7 _61-0 _2fyi _3qnu"><div class="_3qn7 _61-0 _2fyi _3qnf"><i class="_8j62 img sp_a3I1dpr7eZf sx_11dff9" alt=""></i><div class="_2l6m">1491</div></div><div class="_2l6k _8j67">(Sent stars for the first time)</div></div></div></div></div><div class="_3qn7 _61-0 _2fyi _3qnf"></div></div></div></span>
<span><div class="_2l6d _4-u2 _4-u8"><div class="_2l6e _8cjq"><div class="_3qn7 _61-3 _2fyi _3qnf _4tau"><div class="_6g3g" style="flex: 1 1 50%; order: 0;"><div><div class="_3qn7 _61-0 _2fyi _3qnf"><div class="_2l6g"><img class="_2l6h img" src="https://scontent.xx.fbcdn.net/v/t1.0-1/p50x50/53013932_10213056605158788_5125115265213792256_n.jpg?_nc_cat=107&amp;_nc_oc=AQn_YN3rPe1NYgR8YzfZzLTTq6vF6H6G841btZ_hhP8dUrifofjznkSL3GHYPRVr2vRPQYLANAytqUyJALGEg03A&amp;_nc_ad=z-m&amp;_nc_cid=0&amp;_nc_zor=9&amp;_nc_ht=scontent.xx&amp;oh=a8857018964cd1cc7132e781a3f37f94&amp;oe=5E610088" alt=""></div><div class="_2l6i"><div class="_6qad"><a class="_2l6j" uid="1502794359" data-hovercard="/ajax/hovercard/user.php?id=1502794359" href="/1502794359">Robert Bojor</a></div><div class="_2l6k"><span>just now</span></div></div></div><div class="_2l6n"><span><span>Lorem ipsum torquent rhoncus lectus, consequat eleifend.</span></span></div><div class="_2l6l _3qn7 _61-0 _2fyi _3qnu"><div class="_3qn7 _61-0 _2fyi _3qnf"><i class="_8j62 img sp_a3I1dpr7eZf sx_11dff9" alt=""></i><div class="_2l6m">9796</div></div><div class="_2l6k _8j67">(Sent stars for the first time)</div></div></div></div></div><div class="_3qn7 _61-0 _2fyi _3qnf"></div></div></div></span>
<span><div class="_2l6d _4-u2 _4-u8"><div class="_2l6e _8cjq"><div class="_3qn7 _61-3 _2fyi _3qnf _4tau"><div class="_6g3g" style="flex: 1 1 50%; order: 0;"><div><div class="_3qn7 _61-0 _2fyi _3qnf"><div class="_2l6g"><img class="_2l6h img" src="https://scontent.xx.fbcdn.net/v/t1.0-1/p50x50/53013932_10213056605158788_5125115265213792256_n.jpg?_nc_cat=107&amp;_nc_oc=AQn2tzewd4qf2Q1tX238WOeSFh47oqBwWhkUIsQNGAIEUOWNPYWm0K5bPrGQkLqOmLrAA9B1bpcf24mF1R31U3dQ&amp;_nc_ad=z-m&amp;_nc_cid=0&amp;_nc_zor=9&amp;_nc_ht=scontent.xx&amp;oh=52c776ba5d94f3777d96fc9a95794f6c&amp;oe=5E610088" alt=""></div><div class="_2l6i"><div class="_6qad"><a class="_2l6j" uid="1502794359" data-hovercard="/ajax/hovercard/user.php?id=1502794359" href="/1502794359">Robert Bojor</a></div><div class="_2l6k"><span>just now</span></div></div></div><div class="_2l6n"><span><span>Lorem ipsum aenean.</span></span></div><div class="_2l6l _3qn7 _61-0 _2fyi _3qnu"><div class="_3qn7 _61-0 _2fyi _3qnf"><i class="_8j62 img sp_a3I1dpr7eZf_2x sx_b1dfa1" alt=""></i><div class="_2l6m">9435</div></div><div class="_2l6k _8j67">(Sent stars for the first time)</div></div></div></div></div><div class="_3qn7 _61-0 _2fyi _3qnf"></div></div></div></span>
 */

const RouterWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`
const DarkOverlay = styled.div`
  flex: 1;
  display:flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .45);
  z-index: 0;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
  &.loading {
    opacity: 0;
  }
`
const VideoBackground = styled.video`
  position: fixed;
  z-index:-2;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  @media(min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }
  @media(max-aspect-ratio: 16/9) {
    width: auto;
    height: 100%;
  }
  `
const VideoLoader = styled(VideoBackground)`
  position: fixed;
  opacity: 0;
  top: 0;
  transition: opacity 0.75s ease;
  z-index: -1;
  &.shown {
    opacity: 1;
  }
`
export default App
