import React, {Component} from 'react'
import styled from 'styled-components'
import {CDN} from '../config'
import {FaInstagram, FaFacebookF, FaTwitter} from 'react-icons/all'
import {colors} from '../theme/colors'

class ComingSoon extends Component<any> {
  render() {
    return (
      <Logo>
        <LogoImage src={`${CDN}/img/logo-main.svg`} alt={`RedBird Games Live`}/>
        <H1>Coming soon</H1>
        <SocialIcons>
          <a href={`https://instagram.com/RBGamesLive`} target={`_blank`}><FaInstagram size={32}/></a>
          <a href={`https://facebook.com/RBGamesLive`} target={`_blank`}><FaFacebookF size={32}/></a>
          <a href={`https://twitter.com/RBGamesLive`} target={`_blank`}><FaTwitter size={32}/></a>
        </SocialIcons>
      </Logo>
    )
  }
}

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-family: "Orbitron";
  text-align:center;
`
const LogoImage = styled.img`
width: 400px;
height: 400px;
`

const SocialIcons = styled.div`
  a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
  }
  a:hover {
    color: #e22125;
  }
`

const H1 = styled.h1`
  color: ${colors.white};
  font-size: 80px;
  font-weight: 400;
  margin: 40px 0;
`

export default ComingSoon
