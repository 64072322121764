import React from 'react';
import {render} from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'mobx-react'
import {stores} from './stores'
import {ThemeProvider} from 'styled-components'
import {Theme} from './theme'

require('dotenv').config()

render(
  <Provider {...stores}>
    <ThemeProvider theme={Theme}>
      <App />
    </ThemeProvider>
  </Provider>
  ,
  document.getElementById('root')
);

serviceWorker.unregister();
