import React from 'react'
import {inject, observer} from 'mobx-react'
import {Container, Row} from '../theme/elements'
import Header from '../components/Header'
import {CDN, FBPixel} from '../config'
import styled from 'styled-components'
import ReactPixel from 'react-facebook-pixel'
import {colors} from '../theme/colors'
ReactPixel.init(FBPixel);

@inject('generalStore')
@observer
class Home extends React.Component<any> {
  componentDidMount(): void {
    ReactPixel.pageView();
  }

  render() {
    const {generalStore:{loading}} = this.props
    return (
      <Container className={loading.general ? `loading` : ``}>
        <Header/>
        <HomeData>
          <LogoImage src={`${CDN}/img/logo-main.svg`} alt={`RedBird Games Live`}/>
          <H1>Red Bird Games Live</H1>
          <H2>Coder / Gamer / Streamer</H2>
        </HomeData>
      </Container>
    )
  }
}

const LogoImage = styled.img`
  width: 400px;
  height: 400px;`
const HomeData = styled(Row)`
  margin-top: 60px;
  flex-direction: column !important;
  align-items: center;`
const H1 = styled.h1`
  color: ${colors.orange}
  font-size: 40px;
  margin: 30px 0 0 0;`
const H2 = styled.h2`
  color: ${colors.deepOrange}
  font-size: 24px;`
export default Home
